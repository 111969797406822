/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){


      //MENU / NAVIGATION
      var lastScrollTop = 0;
      $(window).scroll(function(event){
         var st = $(this).scrollTop();

         if($(window).width() <= 768){
          if($('body').hasClass('scrolled')){
            $('body').removeClass('scrolled');
          }        
         }else {
           if (st > lastScrollTop){
              if(st >= 300){
                $('body').addClass('scrolled');
              }
           } else {
              if(st <= 300){       
                $('body').removeClass('scrolled');
              }
           }
         }

        
         lastScrollTop = st;
      });

      $('#menu-trigger').on('click', function() {
        $('#mobile-nav').toggleClass('open');
        $('#menu-trigger').toggleClass('open');
        $('body').toggleClass('no-scroll');
        $('#main-header').toggleClass('open');
      });

      $('.fw-slider').slick({
        fade: true,
        arrows: false,
        dots: true,
        autoplay: true
      });

      $('.service-slider').slick({
        fade: true,
        arrows: true,
        dots: false,
        autoplay: false
      });

      $('.parcours-slider').slick({
        fade: true,
        arrows: false,
        dots: true,
        autoplay: false,
        appendDots: $('.parcours-pager')
      });

      if($('#menu-services').length > 0) {
        $('#menu-services li a').each(function() {
          var current_url =  $(this).attr('href');

          $(this).attr('href', current_url+"#service");

        });
      }

      if($(this).scrollTop() > 0){
        $('body').addClass('scrolled');
      }

      if($('.grid').length > 0){

        $('.grid').masonry({
          // set itemSelector so .grid-sizer is not used in layout
          itemSelector: '.grid-item',
          // use element for option
          columnWidth: '.grid-sizer',
          //percentPosition: true,
          gutter: 15
        });

      }

      $('#gallery-photos .grid.hidden').css('display', 'none');

      $('#gallery-filters li').on('click', function() {
        var cat_id = $(this).attr('data-id');
        
        resetGrid(cat_id);

        $('#gallery-filters li.active').removeClass('active');
        $(this).addClass('active');

      });

  }

  function resetGrid(cat_id) {

    hideGrid();

    $('#gallery-photos .grid').each(function() {
        var current_id = $(this).attr('data-id');


        if(current_id == cat_id){
          $(this).slideDown();
        }else {
          $(this).slideUp();
        }

    });

    $('.grid').masonry('reloadItems');

    setTimeout(function() {    

      showGrid();

    }, 500);
    

  }

  function hideGrid() {
    $('#gallery-photos .grid').css('opacity', '0');
  }

  function showGrid() {
    $('#gallery-photos .grid').css('opacity', '1');
  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
